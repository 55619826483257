body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
}

/* Note: for use anywhere */
.debug {
    /* border: 1px solid green; */
}
  
.centered {
    margin: 0px auto;
    text-align: center;
}
  
  
.page-title-widget .lit-logo {
    width: 200px;
    /* border: 1px solid red; */
    margin: 0px auto;
}
  
.page-title-widget .school-logo {
    width: 75%;
}

.page-intro-widget h4, .page-title-widget h4 {
    text-align: center;
}
  
.page-intro-widget h4 em, .page-title-widget h4 em {
    color: #2E303E;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

.page-intro-widget h4.intro, .page-title-widget h4.intro {
    padding-top: 30px;
    padding-bottom: 30px;
    /* border: 1px solid red; */
}
  
.copyright-logo-combo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
	padding-top: 2em;
}

.copyright-logo-combo .logo {
    width: 100px;
}

.copyright-logo-combo .copyright, div .copyright {
    color: #999;
}

/* Note: this is for FormControl > HelpBlock, not a custom widget */
form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}

/* Note: for buttons anywhere */
form .btn-school {
    max-width: 180px;
    min-width: 150px;
    width: 50%;
    margin: 0 auto;
    margin-top: 30px;
  }

.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .lander div {
  padding-top: 20px;
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .notes p {
  color: #666;
}

.Home .school-logo {
  max-width: 700px;
  width: 100%;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}



/* @media all and (min-width: 320px) { */
  .Signup {
    /* background-color: red; */
    /* border: 1px solid red; */
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 360px;
    min-width: 280px;
    width: 90%;
  }
/* } */



/* @media all and (min-width: 320px) { */
  .Details {
    /* background-color: red; */
    /* border: 1px solid red; */
    padding: 60px 0;
  }

  .Details form {
    margin: 0 auto;
    /* max-width: 360px; */
    min-width: 280px;
    width: 90%;
  }
/* } */

.Details form .radios {
  font-size: 16px;
}

.Details div.rating-question {
  display: flex;
  flex-direction: row;
  justify-content: space-around
}

.Details .spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

element.style {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.Details form .checkbox label {
  padding-left: 30px;
}

.Details form .checkbox input {
  margin-left: -30px;
}



/* @media all and (min-width: 320px) { */
    .Screen {
        /* background-color: red; */
        /* border: 1px solid red; */
        padding: 60px 0;
      }
    
      .Screen form, div.Screen {
        margin: 0 auto;
        max-width: 360px;
        min-width: 280px;
        width: 90%;
      }
    /* } */

.NotFound {
  padding-top: 100px;
  text-align: center;
}

Option {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0
}
  
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

