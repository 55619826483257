/* Note: for use anywhere */
.debug {
    /* border: 1px solid green; */
}
  
.centered {
    margin: 0px auto;
    text-align: center;
}
  
  
.page-title-widget .lit-logo {
    width: 200px;
    /* border: 1px solid red; */
    margin: 0px auto;
}
  
.page-title-widget .school-logo {
    width: 75%;
}

.page-intro-widget h4, .page-title-widget h4 {
    text-align: center;
}
  
.page-intro-widget h4 em, .page-title-widget h4 em {
    color: #2E303E;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

.page-intro-widget h4.intro, .page-title-widget h4.intro {
    padding-top: 30px;
    padding-bottom: 30px;
    /* border: 1px solid red; */
}
  
.copyright-logo-combo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
	padding-top: 2em;
}

.copyright-logo-combo .logo {
    width: 100px;
}

.copyright-logo-combo .copyright, div .copyright {
    color: #999;
}

/* Note: this is for FormControl > HelpBlock, not a custom widget */
form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}

/* Note: for buttons anywhere */
form .btn-school {
    max-width: 180px;
    min-width: 150px;
    width: 50%;
    margin: 0 auto;
    margin-top: 30px;
  }
