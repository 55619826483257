

/* @media all and (min-width: 320px) { */
  .Signup {
    /* background-color: red; */
    /* border: 1px solid red; */
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 360px;
    min-width: 280px;
    width: 90%;
  }
/* } */
