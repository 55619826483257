

/* @media all and (min-width: 320px) { */
    .Screen {
        /* background-color: red; */
        /* border: 1px solid red; */
        padding: 60px 0;
      }
    
      .Screen form, div.Screen {
        margin: 0 auto;
        max-width: 360px;
        min-width: 280px;
        width: 90%;
      }
    /* } */
