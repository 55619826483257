

/* @media all and (min-width: 320px) { */
  .Details {
    /* background-color: red; */
    /* border: 1px solid red; */
    padding: 60px 0;
  }

  .Details form {
    margin: 0 auto;
    /* max-width: 360px; */
    min-width: 280px;
    width: 90%;
  }
/* } */

.Details form .radios {
  font-size: 16px;
}

.Details div.rating-question {
  display: flex;
  flex-direction: row;
  justify-content: space-around
}

.Details .spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

element.style {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.Details form .checkbox label {
  padding-left: 30px;
}

.Details form .checkbox input {
  margin-left: -30px;
}
